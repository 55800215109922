window.customSelectsLogic = function customSelectsLogic() {
  const defaultCustomSelect = document.getElementsByClassName(
      'default-custom-select'
  )[0];
  if (defaultCustomSelect) {
    const defaultCustomSelectArr = document.querySelectorAll(
        '.default-custom-select'
    );

    defaultCustomSelectArr.forEach((customSelect, i) => {
      const parentContainer = customSelect.parentElement;

      if (parentContainer.classList.contains('is-open')) {
        customSelect.classList.add('active');
      }

      if (!customSelect.classList.contains('is-init')) {
        customSelect.classList.add('is-init');

        const zIndexValue = defaultCustomSelectArr.length - i;
        customSelect.style.zIndex = zIndexValue + 10;

        const customSelectPlaceholder = customSelect.querySelector(
            '.default-custom-select__placeholder'
        );
        const customSelectList = customSelect.querySelector(
            '.default-custom-select__list'
        );
        const customSelectPlaceholderTextContent = customSelect.querySelector(
            '.default-custom-select__placeholder'
        ).textContent;
        customSelectPlaceholder.setAttribute(
            'data-original-placeholder',
            customSelectPlaceholderTextContent
        );

        customSelect.addEventListener('click', (e) => {
          if (!customSelect.classList.contains('active')) {
            customSelect.classList.add('active');
            parentContainer.classList.add('is-open');
          } else {
            if (
                !e.target.classList.contains('default-custom-select__list') &&
                !e.target.closest('.default-custom-select__list')
            ) {
              customSelect.classList.remove('active');
              parentContainer.classList.remove('is-open');
            }
          }
        });

        document.addEventListener('click', (event) => {
          if (
              !customSelectList.contains(event.target) &&
              !customSelect.contains(event.target) &&
              !event.target.closest('.default-custom-select__list')
          ) {
            customSelect.classList.remove('active');
            parentContainer.classList.remove('is-open');
          }
        });

        const checkboxesSelect = customSelect.querySelectorAll(
            'input[type="checkbox"]'
        );

        function placeholderCheck() {
          const checkedCount = customSelect.querySelectorAll(
              'input[type="checkbox"]:checked'
          ).length;
          /*console.log(
            customSelect.querySelectorAll('input[type="checkbox"]:checked')
          );*/

          if (checkedCount === 1) {
            const checkedItem = customSelect.querySelector(
                'input[type="checkbox"]:checked'
            );
            customSelectPlaceholder.textContent =
                checkedItem.nextElementSibling.nextElementSibling.textContent;
          } else {
            customSelectPlaceholder.textContent =
                checkedCount > 0
                    ? `${checkedCount} items selected`
                    : customSelectPlaceholderTextContent;
          }

          const notCheckedInputArr = customSelect.querySelectorAll(
              'input[type="checkbox"]:not(:checked)'
          );
          if (notCheckedInputArr.length > 0) {
            for (let j = 0; j < notCheckedInputArr.length; j++) {
              notCheckedInputArr[j].parentElement.classList.remove(
                  'is-checked'
              );
            }
          }

          if (checkedCount > 0) {
            const checkedInputArr = customSelect.querySelectorAll(
                'input[type="checkbox"]:checked'
            );
            checkedInputArr.forEach((checkedInput) => {
              const parentEl = checkedInput.parentElement;
              parentEl.classList.add('is-checked');
            });
          }
        }

        function allChecked(checkbox) {
          const checkboxAll = customSelect.querySelector(
              'input[type="checkbox"][value="all"]'
          );
          if (checkbox.value === 'all' && checkbox.checked) {
            console.log(1);
            for (let j = 0; j < checkboxesSelect.length; j++) {
              checkboxesSelect[j].checked = true;
            }
          } else if (checkbox.value === 'all' && checkbox.checked === false) {
            console.log(2);
            for (let j = 0; j < checkboxesSelect.length; j++) {
              checkboxesSelect[j].checked = false;
            }
          } else if (checkboxAll.checked) {
            checkboxAll.click();
          }
          console.log(checkboxAll);
        }

        checkboxesSelect.forEach((checkbox) => {
          checkbox.addEventListener('change', () => {
            allChecked(checkbox);
            placeholderCheck();
          });
        });

        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        for (let j = 0; j < checkboxes.length; j++) {
          checkboxes[j].addEventListener('change', () => {
            /*allChecked(checkboxes[j]);*/
            placeholderCheck();
          });
        }

        placeholderCheck();
      }
    });
  }
};
customSelectsLogic();