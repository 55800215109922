window.choicesArray = [];

window.initChoices = function initChoices(elementsArr) {
  const choicesDOM = document.querySelector(elementsArr);
  if (choicesDOM) {
    const choicesArr = document.querySelectorAll(elementsArr);
    for (let i = 0; i < choicesArr.length; i++) {
      console.log('init');
      const parentContainer = choicesArr[i].parentNode;

      const parentContainerWidth = parentContainer.offsetWidth;

      parentContainer.style.maxWidth = `${parentContainerWidth}px`;

      let multiple = false;

      if (choicesArr[i].multiple && choicesArr[i].hasAttribute('multiple')) {
        multiple = true;
      }

      if (choicesArr[i].classList.contains('abc-type')) {
        const options = Array.from(choicesArr[i].options);
        options.sort((a, b) => a.text.localeCompare(b.text, 'uk', { sensitivity: 'base' }));

        const choices = new Choices(choicesArr[i], {
          searchEnabled: true,
          itemSelectText: '',
          placeholder: true,
          searchPlaceholderValue: 'Search',
          removeItems: true,
          removeItemButton: multiple,
          duplicateItemsAllowed: false,
          addItems: true,
          allowHTML: false,
          shouldSort: false,
          paste: true,
          addItemFilter: (value) => true,
          allowAdditions: true,
          fuseOptions: {
            includeScore: true,
            threshold: 0.3,
            keys: ['label', 'value'],
          },
        });

        choices.setChoices(
            options.map(option => ({
              value: option.value,
              label: option.text,
              selected: option.selected,
              disabled: option.disabled,
            })),
            'value',
            'label',
            true
        );

        choicesArray.push(choices);
      } else {
        const choices = new Choices(choicesArr[i], {
          searchEnabled: true,
          itemSelectText: '',
          placeholder: true,
          searchPlaceholderValue: 'Search',
          removeItems: true,
          removeItemButton: multiple,
          duplicateItemsAllowed: false,
          addItems: true,
          allowHTML: false,
          shouldSort: false,
          paste: true,
          addItemFilter: (value) => true,
          allowAdditions: true,
          fuseOptions: {
            includeScore: false,
            threshold: 0.0,
            distance: 0,
            keys: ['label', 'value'],
          },
        });

        choicesArray.push(choices);
      }

      const choicesMultipleElement = parentContainer.querySelector(
          ".choices[data-type='select-multiple']"
      );

      if (choicesMultipleElement) {
        choicesMultipleElement.classList.add('is-multiple');
        /*choicesMultipleElement.addEventListener('click', () => {
          if (parentContainer.querySelector('.is-open')) {
            choices.hideDropdown();
          }
        });*/
      }
    }
  }
};

window.initChoices('[data-choices]');

window.getChoice = function getChoice(select) {
  let selectEl = typeof select === 'object' ? select : document.querySelector(select);
  let choicesArr = window.choicesArray;
  let targetChoice;
  if (choicesArr) {
    window.choicesArray.forEach((choices) => {
      let choicesSelect = choices.passedElement.element;
      if (choicesSelect == selectEl) {
        targetChoice = choices;
      }
    });
  }
  return targetChoice;
};

window.resetChoice = function resetChoice(select) {
  let choice = getChoice(select);
  if (choice) {
    let startValue = choice.config.choices[0].value;
    choice.setChoiceByValue(startValue);
  }
};

$(document).ready(function() {
  const select2Arr = document.querySelectorAll('.select-2');

  select2Arr.forEach(select => {
    const options = Array.from(select.options);
    options.sort((a, b) => a.text.localeCompare(b.text, 'uk', { sensitivity: 'base' }));

    select.innerHTML = '';
    options.forEach(option => select.appendChild(option));

    const parentContainer = select.parentNode;
    const parentContainerWidth = parentContainer.offsetWidth;
    parentContainer.style.maxWidth = `${parentContainerWidth}px`;
  });

  $('.select-2').select2({
    tags: true,
    dropdownPosition: 'below'
  });
});

